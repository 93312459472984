import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'WholeSoft';
  
  

  constructor(
    public http: HttpClient,
    public router: Router
    
  ) { }

  ngOnInit(){

    // try{
    //   this.checkBackendConnection().subscribe(res=> {
    //     if(res.status==200){
    //       this.checkDbConnection().subscribe(res=> {
    //         if(res.status!==200){
    //           this.router.navigate(["underMaintenance"]);
    //         }
           
    //         // console.log("dBCHeck", res);
    //       });
    //     }else{
    //       this.router.navigate(["underMaintenance"]);
    //     }
        
    //     // console.log("haelthCHeck", res);
    //   });
    // } catch (err) {
    //  console.log("Error", err);
    // }
      
      
      
  }

 

  checkDbConnection(){
    
    return this.http.get<any>(`${environment.apiUrl}getCountryList`);
  }

  checkBackendConnection(){
    return this.http.get<any>(`${environment.apiUrl}healthCheck`);
  }

}
