import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-notfound',
  templateUrl: './page-notfound.component.html',
  styleUrls: ['./page-notfound.component.scss'],
})
export class PageNotfoundComponent implements OnInit {
  userRole: any = '';

  constructor() {}

  ngOnInit(): void {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser.role) {
      this.userRole = currentUser.role;
    }
  }
}
