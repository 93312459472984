export const environment = {
  // apiUrl: 'http://127.0.0.1:3224/',
  // apiUrl: 'http://wholesoftbackendphase1.n1.iworklab.com:3224/',
  // apiUrl: 'https://qaapi.wholesoftmarket.com/',
  // apiUrl: 'https://api.staging.wholesoftmarket.com/',
  apiUrl: 'https://api.wholesoftmarket.com/', //AWS
  memberNetworkServiceUrl: 'https://apims1.wholesoftmarket.com/',
  captainDataServiceUrl: 'https://apims4.wholesoftmarket.com/',
  hubspotServiceUrl: 'https://apims3.wholesoftmarket.com/',
  cronJobsServiceUrl: 'https://apims2.wholesoftmarket.com/',

  production: true,

  linkedinClientId: '86a942lxopvnxy',

  googleClientId:
    '494858337395-bpu93697bi4mm9vr90rqaikbdpau5tsu.apps.googleusercontent.com',

  googleClientSecret: 'kap0UxHPZSKX_DAH4c9bw77k',
  
  eikonMapUrl : 'https://api.mapbox.com/styles/v1/deepakkumarc/cl2ej6upr000d14ms0zwtkzag/tiles/256/{z}/{x}/{y}@2x?access_token={accessToken}',
  eikonSecretKey : 'pk.eyJ1IjoiZGVlcGFra3VtYXJjIiwiYSI6ImNsMWcwbTRyZTA0ajYzaW9naTNucGQxYXIifQ.eZAVUNrw21IvgDk6JdD19g',

  chromeExtId : 'ndgllkncallhcmlaofmhcjhomcjgancl'
};

/**
 * Sign-in URL: https://885025817357.signin.aws.amazon.com/console

  User name: VC_APP_ADMIN
  pwd: 123
 */
