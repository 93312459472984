import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
  
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthService } from "../services/auth.service";


import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService,
    public router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        // console.log("Error in  API", err)
        if (err.status === 401) {
          // auto logout if 401 response returned from api
         let registeredEmail = JSON.parse(localStorage.getItem('currentUser'));
   
         if(registeredEmail?.role){
            this.authenticationService.logout();
            location.reload(); 
          }
           
        } 
        else if(err.status==0){
          

          // this.router.navigate(["underMaintenance"]);
        }

        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }

  
}
