

   <div class="row">
    <div class="col-sm-4">
        <aside class="aside-side">
            <div class="aside-mr"></div>
            <div class="side-inner">
                <div class="side-one">

                    <div class="box-content">
                        <div class="whole-logo-container">
                            <img src="../assets/images/logowhole.svg" alt="">
                        </div>
                        <h5>
                            Your Trusted Executive Network for<br> Personal & Professional Growth.
                        </h5>
                        <h6 id="heading-6">
                            AI-powered discovery, analysis and enrichment of your trusted network.
                        </h6>
                    </div>
                </div>
            </div>
            <div class="aside-point"></div>
            <span class="fa fa-times" aria-hidden="true"></span>
            <div>
                <div id="carouselExampleIndicators" class=" carousel slide" data-ride="carousel">
                    <ol class="carousel-indicators">
                        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="6"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="7"></li>
                    </ol>
                    <div class="carousel-inner">
                        <div class=" carousel-item active" data-bs-interval="25000">
                            <div class="card-size">
                                <div class="card-body h-100">
                                    <img class="d-block ml-auto mr-auto img-size"
                                        src="../../../assets/images/Sidebar-carousel/1.svg" alt="First slide">
                                    <div class="d-flex align-items-center h-100 lh-base">
                                        <span class="card-text text-white">
                                            You’re a Sales Exec and you want to connect with a decision-maker at one of
                                            your prospect companies but
                                            haven't had the opportunity to meet? <span style="color:#FFBD9E">Go to
                                                Eikon</span>, select the account,
                                            or even better, maybe the guy himself, and get a path plotted to achieve
                                            your objective. Follow the
                                            recommendations, and you’ll be soon in the office of your target.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item" data-bs-interval="25000">
                            <div class="card" class="card-size">
                                <div class="card-body h-100">
                                    <img class="d-block ml-auto mr-auto img-size"
                                        src="../../../assets/images/Sidebar-carousel/2.svg" alt="First slide">
                                    <div class="d-flex align-items-center h-100 lh-base">
                                        <span class="card-text text-white">
                                            You’re an independent consultant, and you’re looking to find new engagement
                                            opportunities. It’s all
                                            about being introduced to the right C-Level Execs. <span
                                                style="color:#FFBD9E">Go to Eikon</span>.
                                            Select C-Levels. Follow the WSM Eikon recommendations, and get your path
                                            plotted towards your next
                                            assignments.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item " data-bs-interval="2000">
                            <div class="card-size">
                                <div class="card-body h-100">
                                    <img class="d-block ml-auto mr-auto img-size"
                                        src="../../../assets/images/Sidebar-carousel/3.svg" alt="First slide">
                                    <div class="d-flex align-items-center h-100 lh-base">
                                        <span class="card-text text-white">You’re a C-Level or SVP-level
                                            Exec of a corporate account, who’s attracted by the start-up world as your
                                            next step in your career.
                                            <span style="color:#FFBD9E">Go to Eikon</span>. And check who you could
                                            easily get connected with, in
                                            that world.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item " data-bs-interval="2000">
                            <div class="card-size">
                                <div class="card-body h-100">
                                    <img class="d-block ml-auto mr-auto img-size"
                                        src="../../../assets/images/Sidebar-carousel/4.svg" alt="First slide">
                                    <div class="d-flex align-items-center h-100 lh-base">
                                        <span class="card-text text-white">You’re a start-up founder
                                            craving funds. You’ve exhausted cold reach-outs to VCs or Seed funds. <span
                                                style="color:#FFBD9E">Go to
                                                Eikon</span> and get your path plotted to some good contacts at funds
                                            who will open their doors.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item " data-bs-interval="2000">
                            <div class="card" class="card-size">
                                <div class="card-body h-100">
                                    <img class="d-block ml-auto mr-auto img-size"
                                        src="../../../assets/images/Sidebar-carousel/5.svg" alt="First slide">
                                    <div class="d-flex align-items-center h-100 lh-base">
                                        <span class="card-text text-white">You’re a CPO or Talent
                                            officer. You’d like to check the candidates for jobs for which networks are
                                            a must (sales reps, biz dev,
                                            …) do really have a strong Rolodex. Request <span
                                                style="color:#FFBD9E">their Eikon</span> during the
                                            interview process. That will be the ultimate validator of experience.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item " data-bs-interval="2000">
                            <div class="card" class="card-size">
                                <div class="card-body h-100">
                                    <img class="d-block ml-auto mr-auto img-size"
                                        src="../../../assets/images/Sidebar-carousel/6.svg" alt="First slide">
                                    <div class="d-flex align-items-center h-100 lh-base">
                                        <span class="card-text text-white">You’re looking for your next
                                            professional challenge and you’ve identified your dream job. Don’t apply
                                            blindly. <span style="color:#FFBD9E">Go to Eikon</span>, select the company
                                            you’d like to apply to, and get your path
                                            plotted to the people who can provide you with a personal introduction to an
                                            executive there.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item " data-bs-interval="2000">
                            <div class="card" class="card-size">
                                <div class="card-body h-100">
                                    <img class="d-block ml-auto mr-auto img-size"
                                        src="../../../assets/images/Sidebar-carousel/7.svg" alt="First slide">
                                    <div class="d-flex align-items-center h-100 lh-base">
                                        <span class="card-text text-white">You’re a networking person,
                                            this has always been in your DNA. Take the next step to rationally build the
                                            best network ever. <span style="color:#FFBD9E">Go to Eikon</span>, invite
                                            all your strong connections, discover your extended
                                            trusted network, and gradually enrich your purpose-driven network.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item " data-bs-interval="2000">
                            <div class="card" class="card-size">
                                <div class="card-body h-100">
                                    <img class="d-block ml-auto mr-auto img-size"
                                        src="../../../assets/images/Sidebar-carousel/8.svg" alt="First slide">
                                    <div class="d-flex align-items-center h-100 lh-base">
                                        <span class="card-text text-white">As a successful Sales Exec,
                                            you know the following stat - <span style="font-style: italic;
                              color: #c0f0f7 !important;">84% of B2B decision-makers start the buying process with a
                                                referral</span>. Referrals are a powerful business growth strategy.
                                            <span style="color:#FFBD9E">Go to
                                                Eikon</span> and identify who can recommend you to. </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="false"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="false"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </aside>
    </div>
    <div class="col-sm-8">
    <div class="container text-center">
        <h1 class="text-secondary text-center m-2 mt-5">It's on us!</h1>
        <div class="div">
            <p>The Eikon service is undergoing maintenance tests.<br> It will resume momentarily. Sorry for the inconvenience.</p>
        </div>
        <br />
        <br />
        <img src="../../assets/images/404.svg" height="350" width="auto" alt="" />
    </div>
    </div>
</div>