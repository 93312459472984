import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export enum TypesEnum  {
  Primary = 'primary',
  Success = 'success',
  Danger = 'danger',
  Info = 'info',
  Warning = 'warning',
  Default = 'default'
}

@Component({
  selector: 'app-callout',
  templateUrl: './callout.component.html',
  styleUrls: ['./callout.component.scss']
})
export class CalloutComponent implements OnInit {

  @Input() type: TypesEnum = TypesEnum.Default;
  @Input() header: string = '';
  @Input() text: string = '';
  @Input() openStart: boolean = false;
  @Input() icon: boolean = false;
  @Output() onClose = new EventEmitter<boolean>();

  public isVisible: boolean = false;

  constructor() { }

  public ngOnInit() {
    if(this.openStart) {
      this.isVisible = true;
    }
  }

  public close(): void {
    const wasVisible = this.isVisible;
    this.isVisible = false;

    if(wasVisible) {
      this.onClose.emit(true);
    }
  }

  public open(config?: {type: TypesEnum, header: string, text: string}): void {
    this.isVisible = true;

    if(config) {
      this.type = config.type;
      this.header = config.header;
      this.text = config.text;
    }
  }

}
