<div class="container text-center">
  <h1 class="text-secondary text-center m-5">Oops, it's not you. It's on us</h1>
  <br />
  <a
    *ngIf="userRole === 'agent'"
    [routerLink]="['/dashboard/rp/summary']"
    class="btn btn-warning btn-lg mb-5"
    >Home</a
  >
  <a
    *ngIf="userRole === 'seller'"
    [routerLink]="['/dashboard/isv']"
    class="btn btn-warning btn-lg mb-5"
    >Home</a
  >
  <a
    *ngIf="userRole === 'buyer'"
    [routerLink]="['/']"
    class="btn btn-warning btn-lg mb-5"
    >Home</a
  >
  <br />
  <img src="../../assets/images/404.svg" height="350" width="auto" alt="" />
</div>
