import { environment } from "./../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class IsvService {
  public productName = localStorage.getItem("productName");
  constructor(private http: HttpClient) {}

  //#region Onboarding GSA1
  addProductName(payload, productId?): Observable<any> {
    if (productId) {
      return this.http.patch<any>(
        `${environment.apiUrl}addProductStep1/start?productId=${productId}`,
        payload
      );
    } else {
      return this.http.patch<any>(
        `${environment.apiUrl}addProductStep1/start`,
        payload
      );
    }
  }

  addRole(payload, productId): Observable<any> {
    let params = new HttpParams().set("productId", productId);
    return this.http.patch<any>(
      `${environment.apiUrl}addProductStep1/role`,
      payload,
      { params: params }
    );
  }

  addTargetIndustry(payload, productId) {
    let params = new HttpParams().set("productId", productId);
    return this.http.patch<any>(
      `${environment.apiUrl}addProductStep1/industry`,
      payload,
      { params: params }
    );
  }

  addDomain(payload, productId) {
    let params = new HttpParams().set("productId", productId);
    return this.http.patch<any>(
      `${environment.apiUrl}addProductStep1/domain`,
      payload,
      { params: params }
    );
  }

  addGeography(payload, productId) {
    let params = new HttpParams().set("productId", productId);
    return this.http.patch<any>(
      `${environment.apiUrl}addProductStep1/geography`,
      payload,
      { params: params }
    );
  }
  //#endregion

  //#region Onboarding GSA2

  addProductDetails(payload, productId) {
    let params = new HttpParams().set("productId", productId);
    return this.http.patch<any>(
      `${environment.apiUrl}addProductStep2/detail`,
      payload,
      { params: params }
    );
  }

  addCustomerRefCoverage(payload, productId) {
    let params = new HttpParams().set("productId", productId);
    return this.http.patch<any>(
      `${environment.apiUrl}addProductStep2/reference`,
      payload,
      { params: params }
    );
  }

  addRoiPayback(payload, productId) {
    let params = new HttpParams().set("productId", productId);
    return this.http.patch<any>(
      `${environment.apiUrl}addProductStep2/roi`,
      payload,
      { params: params }
    );
  }

  addTargetSize(payload, productId) {
    let params = new HttpParams().set("productId", productId);
    return this.http.patch<any>(
      `${environment.apiUrl}addProductStep2/target`,
      payload,
      { params: params }
    );
  }

  addUsExperience(payload, productId) {
    let params = new HttpParams().set("productId", productId);
    return this.http.patch<any>(
      `${environment.apiUrl}addProductStep2/us`,
      payload,
      { params: params }
    );
  }

  getAlgoServiceDetails(payload) {
    return this.http.post<any>(
      `${environment.apiUrl}algo4Service`,
      payload
    );
  }

  //#endregion

  //#region Onboarding GSA3

  addSalesProcessDetails(payload, productId) {
    let params = new HttpParams().set("productId", productId);
    return this.http.patch<any>(
      `${environment.apiUrl}addProductStep3`,
      payload,
      { params: params }
    );
  }
  //#endregion

  //#region ProductList
  getSellerProductList(payload?) {
    let params = new HttpParams();
    
    if(payload?.count) {
      params = params.append('count', payload.count);
    }

    if(payload?.skip) {
      params = params.append('skip', payload.skip);
    }

    if(payload?.searchParam) {
      params = params.append('searchPattern', payload.searchParam);
    }

    return this.http.get<any>(`${environment.apiUrl}productList`, { params });
  }

  //#endregion


  getSellerProductRegData(productId, stepIndex) {
    return this.http.get<any>(
      `${environment.apiUrl}productByStep/` + productId + "/" + stepIndex
    );
  }

  getRegisteredProdutDetails(productId) {
    return this.http.get<any>(`${environment.apiUrl}product/` + productId);
  }

  getonBoardProductStatus() {
    return this.http.get<any>(`${environment.apiUrl}onBoardProductStatus`);
  }

  getproductStatus(productId) {
    return this.http.get<any>(
      `${environment.apiUrl}productStatus/` + productId
    );
  }
  // step3
  getStakeHolder() {
    return this.http.get<any>(`${environment.apiUrl}stakeHoldereDropdown/`);
  }

  getStages() {
    return this.http.get<any>(`${environment.apiUrl}stages`);
  }
  // step3
  getProductStatus(productId, stepIndex) {
    return this.http.get<any>(
      `${environment.apiUrl}productByStep/` + productId + "/" + stepIndex
    );
  }

  accountRequestStatus() {
    return this.http.get<any>(`${environment.apiUrl}originDropdown`);
  }
  accountRequestStatusNew() {
    return this.http.get<any>(`${environment.apiUrl}accountRequestStatus`);
  }
  geographyDropdown() {
    return this.http.get<any>(`${environment.apiUrl}geographyDropdown`);
  }

  isvIndustryDropdown() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      }),
    };
    return this.http.get<any>(`${environment.apiUrl}industryDropdown`);
  }
  companyDropdown() {
    return this.http.get<any>(`${environment.apiUrl}companySizeDropdown`);
  }
  removeFile(payload) {
    return this.http.put<any>(
      `${environment.apiUrl}removeDocument`,
      payload
    );
  }
}
