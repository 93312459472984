import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalloutComponent } from './components/callout/callout.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BaseUnsubscribeeComponent } from './components/base-unsubscribee/base-unsubscribee.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { DialogSuccessMessageComponent } from './components/dialog-success-message/dialog-success-message.component';
import { InfoMessageComponent } from './components/info-message/info-message.component';


@NgModule({
  declarations: [
    CalloutComponent,
    BaseUnsubscribeeComponent,
    SpinnerComponent,
    DialogSuccessMessageComponent,
    InfoMessageComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    NgbModule,
    MatIconModule
  ],
  exports: [
    CalloutComponent,
    NgbModule,
    SpinnerComponent,
    DialogSuccessMessageComponent,
    InfoMessageComponent
  ]
})
export class SharedModule { }
