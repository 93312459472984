import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, concat, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { map, share, shareReplay } from 'rxjs/operators';
import { format, differenceInDays } from 'date-fns';
import * as multerExpress from 'multer-s3'
import { User } from '../lib/user';

interface IGeneralResponse {
  message: 'Success' | 'Error';
  status: number;
}
interface ICompanySizes extends IGeneralResponse {
  details: {
    companySizeDescriptions: { _id: string, value: string }[]
  }
}

interface AWSResponse {
  awsFileInfo: {
    [key in FileType] : Express.Multer.File
  };
}

export enum FileType {
  ProfilePhoto = 'profile_photos',
  IsvDocument = 'isv_documents',
  HlcDocument = 'hlc_documents',
  CompanyLogos = 'company_logos',
  CharityLogos = 'charity_logos',
  ProfileConnections = 'profile_connections'
}
@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private _spinnerState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public spinnerState = this._spinnerState.asObservable();

  private _spinnerStates: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public spinnerStates = this._spinnerStates.asObservable();
  public companyDropdownCache$;
  profileDataObservable: Observable<any>;
  profileDataObservableSOC: Observable<any>;
  

  constructor(private http: HttpClient, private toastr: ToastrService) {}

  public onImgUserError(event) {
    event.target.src = 'assets/images/user.png';
  }

  public onImgCompanyError(event) {
    event.target.src = 'assets/images/company.png';
  }

  // Substarct Days
  public substractDays(dayOne, dayTwo) {
    const currentDay = new Date(dayTwo);
    currentDay.setSeconds(0);
    currentDay.setMinutes(0);
    currentDay.setHours(0);
    return differenceInDays(new Date(dayOne), new Date(currentDay));
  }

  // Convert Days
  public convertDate(dayOne) {
    return new Date(dayOne);
  }

  // Format Days
  public formatDays(dayOne) {
    return format(new Date(dayOne), 'dd MMM yyyy');
  }

  // KINDLY DON'T REMOVE IT'S USED IN RP PROFILE
  showSuccess(text) {
    Swal.fire({
      text: text,
    });
  }

  showToastMessage(type, message, duration = 3000) {
    switch (type) {
      case 'SUCCESS':
        this.toastr.success(message, 'Success', {
          progressBar: true,
          progressAnimation: 'increasing',
          timeOut: duration,
          tapToDismiss: true,           
        });
        break;
      case 'ERROR':
        this.toastr.error(message, 'Error', {
          progressBar: true,
          progressAnimation: 'increasing',
          timeOut: duration,
          tapToDismiss: true,
        });
        break;
      case 'INFO':
        this.toastr.info(message, 'Info', {
          progressBar: true,
          progressAnimation: 'increasing',
          timeOut: duration,
          tapToDismiss: true,
        });
        break;
    }
  }
  showToastMessage1(type, message, duration = 3000) {
    switch (type) {
      case 'SUCCESS':
        this.toastr.success(message, 'Success', {
          progressBar: true,
          progressAnimation: 'increasing',
          timeOut: duration,
          tapToDismiss: true,
          positionClass:'toast-center-center'
        });
        break;
      case 'ERROR':
        this.toastr.error(message, 'Error', {
          progressBar: true,
          progressAnimation: 'increasing',
          timeOut: duration,
          tapToDismiss: true,
          positionClass:'toast-center-center'
        });
        break;
      case 'INFO':
        this.toastr.info(message, 'Info', {
          progressBar: true,
          progressAnimation: 'increasing',
          timeOut: duration,
          tapToDismiss: true,
          positionClass:'toast-center-center'
        });
        break;
    }
  }

  // getProfileInfo(id) {
  //   return this.http.get<any>(`${environment.apiUrl}getProfileConnection/${id}`);
  // }
  //industry Dropdown
  getIndustries() {
    return this.http.get<any>(`${environment.apiUrl}industryDropdown`);
  }
  //domain subdomain

  getDomain() {
    return this.http.get<any>(`${environment.apiUrl}domainDropdown`);
  }

  getsubDomain(id: any) {
    return this.http.get<any>(`${environment.apiUrl}subDomainDropdown/` + id);
  }
  getgeography() {
    return this.http.get<any>(`${environment.apiUrl}geographyDropdown`);
  }

  getInfoRequestInfo(sellerId: any) {
    return this.http.get<any>(`${environment.apiUrl}getIntroRequestData/${sellerId}`);
  }

  getOnboardingStatus(productId: any) {
    return this.http.get<any>(
      `${environment.apiUrl}geographyDropdown/` + productId
    );
  }

  // get countries
  getCountries() {
    return this.http.get<any>(`${environment.apiUrl}countryDropdown/`);
  }

  //business size dropdown

  getBusinessSize(): Observable<ICompanySizes> {
    return this.http.get<ICompanySizes>(`${environment.apiUrl}companySizeDropdown/`);
  }

  getCompetitor() {
    return this.http.get<any>(`${environment.apiUrl}companyDropdown/`);
  }

  getAccountName() {
    return this.http.get<any>(`${environment.apiUrl}accountDropDown/`);
    // .pipe(
    //   map((el) => {
    //     // console.log(el);
    //     return el;
    //   })
    // );
  }
  memberSignUp(payload) {
    return this.http.post<any>(`${environment.apiUrl}memberSignup`, payload);
  }

  getAccountForRPWithIntroPropsal(query?) {
    if (!query) {
      return this.http.get<any>(
        `${environment.apiUrl}accountinrpwithintroproposal`
      );
    } else {
      let params = new HttpParams().set('type', query);

      return this.http.get<any>(
        `${environment.apiUrl}accountinrpwithintroproposal`,
        { params }
      );
    }
  }

  getcharity() {
    return this.http.get<any>(`${environment.apiUrl}charityDropdown`);
  }

  getIntroStatusDropdown() {
    // return this.http.get<any>(`${environment.apiUrl}introStatusDropdown`);

    return this.http.get<any>(`${environment.apiUrl}introStatusDropdown`).pipe(
      map((el) => {
        // console.log(el);
        return el;
      })
    );
  }

  getIntroProposalStatusDropDown() {
    // return this.http.get<any>(`${environment.apiUrl}introProposalStatus`);

    return this.http.get<any>(`${environment.apiUrl}introProposalStatus`).pipe(
      map((el) => {
        // console.log(el);
        return el;
      })
    );
  }

  getAllISVDropdown() {
    return this.http.get<any>(`${environment.apiUrl}allIsvs`);
  }

  getUniqueVendor(query?) {
    if (query) {
      let params = new HttpParams().set('type', query);

      return this.http
        .get<any>(`${environment.apiUrl}rpsubmittedintropropsal`, { params })
        .pipe(
          map((el) => {
            return el;
          })
        );
    } else {
      return this.http
        .get<any>(`${environment.apiUrl}rpsubmittedintropropsal`)
        .pipe(
          map((el) => {
            // console.log(el);
            return el;
          })
        );
    }
  }

  getVendorWithClosedDeal() {
    // let params = new HttpParams().set('type', query);
    return this.http
      .get<any>(`${environment.apiUrl}rpdealsclosedwithvendor`)
      .pipe(
        map((el) => {
          return el;
        })
      );
  }

  getAccountWithRpOfClosedDeal() {
    return this.http
      .get<any>(`${environment.apiUrl}rpdealsclosedwithaccount`)
      .pipe(
        map((el) => {
          return el;
        })
      );
  }
  getDealofAccountAndVendor() {
    return this.http
      .get<any>(`${environment.apiUrl}accountdropdownwithisvcloseddeals`)
      .pipe(
        map((el) => {
          return el;
        })
      );
  }
  getDealofRPAndVendor() {
    return this.http
      .get<any>(`${environment.apiUrl}rpdropdownwithisvcloseddeals`)
      .pipe(
        map((el) => {
          return el;
        })
      );
  }

  uploadFile(file: File, fileType: FileType): Promise<AWSResponse> {
    const fileData = new FormData();
    fileData.append(fileType, file);
    console.log("FileData",fileData);
    return this.http.post<AWSResponse>(`${environment.apiUrl}v1/uploadDocument`, fileData).toPromise();
  }

  processCSV(data) {
    return this.http
      .post<any>(`${environment.apiUrl}processCSV`, data);
  }


  getProfileInfo(id?: string) {
    if (this.profileDataObservable) {
      return this.profileDataObservable;
    } else {
      if (id) {
        // return this.profileDataObservable = this.http.get<any>(`${environment.apiUrl}getProfileConnection/${id}`).pipe(share());
        return this.profileDataObservable = this.http.get<any>(`${environment.memberNetworkServiceUrl}getProfileConnection/${id}`).pipe(share());
      }
    }
  }

  getProfileInfoSOC(id?: string) {
    if (this.profileDataObservableSOC) {
      return this.profileDataObservableSOC;
    } else {
      if (id) {
        return this.profileDataObservableSOC = this.http.get<any>(`${environment.memberNetworkServiceUrl}getProfileConnectionSOC/${id}`).pipe(share());
      }
    }
  }

  getProfileInfo2(id?: string) {

    if(id) {
    // return this.http.get<any>(`${environment.apiUrl}getProfileConnection/${id}?l2=true&coopt=true`);
    return this.http.get<any>(`${environment.memberNetworkServiceUrl}getProfileConnection/${id}?l2=true&coopt=true`);
    }
    
    }
  
  getProfileConnectionLite(id?: string) {

    if(id) {
    // return this.http.get<any>(`${environment.apiUrl}getProfileConnectionLite/${id}?l2=true&coopt=true`);
    return this.http.get<any>(`${environment.memberNetworkServiceUrl}getProfileConnectionLite/${id}?l2=true&coopt=true`);
    }
    
  }

  getProfileConnectionLite2(id?: string, view?) {

      if(id) {
      // return this.http.get<any>(`${environment.apiUrl}getProfileConnectionLite/${id}?l2=true&coopt=true`);
      return this.http.get<any>(`${environment.memberNetworkServiceUrl}getProfileConnectionLite2/${id}/${view}?l2=true&coopt=true`);
      }
      
  }
    
  getEnrichFilter() {
    return this.http.get<any>(`${environment.apiUrl}recommendationsForUser`);
  }
    
    
    

  
  public static sortByField(
    array: any[],
    property: any,
    fieldType: 'date' | 'number' | 'string' | 'boolean' | 'status',
    asc: 'asc' | 'desc' = 'asc',
    additionalproperty?: string,
    concatinateWith?: string
  ): any[] {
    let copyAr = array.slice();

    copyAr.sort((a, b) => {
      if(fieldType === 'date') {
        const leftDate = +new Date(a[property]);
        const rightDate = +new Date(b[property]);

        return Math.sign(leftDate - rightDate);
      } else if(fieldType === 'number') {
        return a[property] - b[property];
      } else if(fieldType === 'string') {
        if(additionalproperty && concatinateWith) {
          if((a[property][additionalproperty] + a[property][concatinateWith]).toLowerCase() > (b[property][additionalproperty] + b[property][concatinateWith]).toLowerCase()) {
            return 1;
          } else if((a[property][additionalproperty] + a[property][concatinateWith]).toLowerCase() < (b[property][additionalproperty] + b[property][concatinateWith]).toLowerCase()) {
            return -1;
          } else {
            return 0;
          }
        } else if(additionalproperty) {
          if(a[property][additionalproperty].toLowerCase() > b[property][additionalproperty].toLowerCase()) {
            return 1;
          } else if(a[property][additionalproperty].toLowerCase() < b[property][additionalproperty].toLowerCase() ) {
            return -1;
          } else {
            return 0;
          }
        } else if(concatinateWith) {
          if(((a[property] + a[concatinateWith]).toLowerCase() > (b[property] + b[concatinateWith]).toLowerCase())) {
            return 1;
          } else if(((a[property] + a[concatinateWith]).toLowerCase() < (b[property] + b[concatinateWith]).toLowerCase())) {
            return -1;
          } else {
            return 0;
          }
        } else {
          if(a[property].toLowerCase() > b[property].toLowerCase()) {
            return 1;
          } else if(a[property].toLowerCase() < b[property].toLowerCase()) {
            return -1;
          } else {
            return 0;
          }
        }
      } else if(fieldType === 'boolean') {
        if(a[property] && !b[property]) {
          return -1;
        } else if(!a[property] && b[property]) {
          return 1;
        } else {
          return 0;
        }
      } else if(fieldType === 'status') {
        if(a[property].value === 'PENDING' && b[property].value !== 'PENDING') {
          return -1;
        }
      }
    });

    if(asc === 'desc') {
      copyAr = copyAr.reverse();
    }

    return copyAr;
  }

  public getAccountDropdownPaginated(lastItem?: string | number, limit?: number) {
    if(!this.companyDropdownCache$) {
      let params = new HttpParams();

      // if(lastItem != null && limit != null) {
        lastItem = new Array(24).fill('a').join('');
        limit = 1;
        params = params.append('itemId', lastItem.toString());
        params = params.append('limit', limit.toString());
      // }

      this.companyDropdownCache$ = this.http.get<any>(`${environment.apiUrl}companyDropdown`, { params })
      .pipe(
        map(res => {
          res.details.companies = SharedService.sortByField(res.details.companies, 'accountName', 'string');
          return res;
        }),
        shareReplay(1));
      return this.companyDropdownCache$;
    }

    return this.companyDropdownCache$;
  }

  public getUserFromLocalstorage(): User {
    const user = localStorage.getItem("currentUser");
    if(user) {
      const userObj = JSON.parse(user) as User;
      return userObj;
    }

    return null;
  }

  public showSpinner(state: boolean) {
    this._spinnerState.next(state);
  }
  public showSpinnerForCard(state: boolean) {
    this._spinnerStates.next(state);
  }
  
  getProfileConnectionL2s(id?: string) {
    // if (id) {
    //   return this.http.get<any>(`${environment.memberNetworkServiceUrl}getProfileConnectionLite/${id}?l2=true`);
    // }

    // if (this.profileDataObservable) {
    //   return this.profileDataObservable;
    // } else {
      if (id) {
        // return this.profileDataObservable = this.http.get<any>(`${environment.apiUrl}getProfileConnection/${id}`).pipe(share());
        return this.profileDataObservable = this.http.get<any>(`${environment.memberNetworkServiceUrl}getProfileConnection/${id}?l2=true`);
        // .pipe(share());
      }
    // }


  }

  checkMemberProgress(payload:any){
    // console.log('payLoad', payload);
    return this.http.post<any>(`${environment.apiUrl}checkMemberProgress`, {
      "userId": payload
    });
  }

  
  getProfilesForForcedInvitation(id?: string) {
    return this.http.get<any>(`${environment.memberNetworkServiceUrl}getProfilesForForcedInvitation/${id}`);
  }
}
