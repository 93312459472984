import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { User } from "../lib/user";
import { ActivatedRoute, Router } from "@angular/router";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { SharedService } from "./shared.service";
import { StepsService } from "./isv.stepsService";
import { SocialAuthService } from "angularx-social-login";
import { HttpHeaders } from "@angular/common/http";
import { Subject } from "rxjs";
import { CookieService } from "ngx-cookie-service";


@Injectable({
  providedIn: "root",
})
export class AuthService {
  private _teacherMessageSource = new Subject<string>();
  teacherMessage$ = this._teacherMessageSource.asObservable();
  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  profiles: any =[];
  showSaveExit: boolean = false;
  cookieValue: any;
  

  constructor(
    public http: HttpClient,
    private router: Router,
    private actRoute: ActivatedRoute,
    public sharedService: SharedService,
    private stepService: StepsService,
    public socialService: SocialAuthService,
    private cookies:CookieService,


  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(payload) {
    return this.http.post<any>(`${environment.apiUrl}v1/login`, payload).pipe(
      map((user) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // user["fullName"] = user.details?.firstName + " " + user.details?.lastName
        // localStorage.setItem("fullName", user.fullName);
        // user["memberImage"] = user.details?.memberImage
        // localStorage.setItem("memberImage",user.memberImage);
        // user["subscriptionType"] = user.details?.subscriptionType
        // localStorage.setItem("subscriptionType", JSON.stringify(user.subscriptionType));
        // user["graphCreated"] = user.details?.graphCreated
        // localStorage.setItem("graphCreated", JSON.stringify(user.graphCreated));
        // user["extractionCompleted"] = user.details?.extractionCompleted;
        // localStorage.setItem("extractionCompleted", JSON.stringify(user.extractionCompleted));
       // console.log("user Details --", JSON.stringify(user.details))
        localStorage.setItem("currentUser", JSON.stringify(user.details));
        localStorage.setItem("token", JSON.stringify(user.details.token));
        this.currentUserSubject.next(null);
        this.currentUserSubject.next(user.details);
        return user;
      })
    );
  }

  userEmailValidation(email){
    return this.http.post<any>(`${environment.apiUrl}userEmailValidation`,{email});
  } 

  loginWithRoles(payload,userRole) {
    return this.http.post<any>(`${environment.apiUrl}loginWithRoles`, {payload, userRole}).pipe(
      map((user) => {
        localStorage.setItem("currentUser", JSON.stringify(user.details));
        localStorage.setItem("token", JSON.stringify(user.details.token));
        this.currentUserSubject.next(null);
        this.currentUserSubject.next(user.details);
        return user;
      })
    );
  }


  jobTitleDropdown() {
    return this.http.get<any>(`${environment.apiUrl}jobTitleDropdown`);

  }

  profileInformation(id: string) {
    return this.http.get<any>(`${environment.apiUrl}getMemberProfileInfo/${id}`);
  }

  getInvitationInfo(id: string) {
    return this.http.get<any>(`${environment.apiUrl}getInvitationInfo/${id}`);
  }

  signUpInvitations(_id,firstName,lastName,linkedinUrl){
    return this.http.post<any>(`${environment.apiUrl}signUpInvitations`,{_id,firstName,lastName,linkedinUrl});
  }

  signUpWSMInvitations(_id,firstName,lastName,linkedinUrl){
    return this.http.post<any>(`${environment.apiUrl}signUpWSMInvitations`,{_id,firstName,lastName,linkedinUrl});
  }

  checkEmailExistsOrNot(email: string) {
    return this.http.get<any>(`${environment.apiUrl}checkEmailExists/${email}`);
  }

  recInnovatorsDetails(id: string) {
    return this.http.get<any>(`${environment.apiUrl}getRecommendedInnovatorsInfo/${id}`);
  }
  signUp(payload) {
    return this.http.post<any>(`${environment.apiUrl}signup`, payload);
  }
  
  memberSignUp(payload) {
    return this.http.post<any>(`${environment.apiUrl}memberSignup`, payload).pipe(
      map((user) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("currentUser", JSON.stringify(user.details));
        localStorage.setItem("token", JSON.stringify(user.details.token));
        this.currentUserSubject.next(user.details);
        return user;
      })
    );
  }

  accountSignUp(payload) {
    return this.http.post<any>(`${environment.apiUrl}buyerSignUp`, payload);
  }

  recInnovatorsConnection(connectorDetails) {
    return this.http.post<any>(`${environment.apiUrl}connectInnovators`, connectorDetails);
  }

  getInnovatorInfo(id: string) {
    return this.http.get<any>(`${environment.apiUrl}connectReqInfo/${id}`);
  }

  profileSignUp(payLoad) {
    return this.http.post<any>(`${environment.apiUrl}accountSignUp`, payLoad).pipe(
      map((user) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("currentUser", JSON.stringify(user.details));
        localStorage.setItem("token", JSON.stringify(user.details.token));
        this.currentUserSubject.next(user.details);
        return user;
      })
    );;
  }

  linkedLoginSendDataToBackendSignUp(code, redirectUrl) {
    const accessCode = {
      code: code,
      redirectUrl: redirectUrl,
    };

    return this.http.post<any>(
      `${environment.apiUrl}linkedinSignup`,
      accessCode
    );
  }

  linkedinLogin(code, redirectUrl) {
    const accessCode = {
      code: code,
      redirectUrl: redirectUrl,
    };

    return this.http
      .post<any>(`${environment.apiUrl}linkedinLogin`, accessCode)
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem("currentUser", JSON.stringify(user.details));
          localStorage.setItem("token", JSON.stringify(user.details.token));
          this.currentUserSubject.next(user.details);
          return user;
        })
      );
  }
  googleLogin(user) {
    const googleDetails = {
      googleToken: user?.id,
      email: user?.email,
    };

    return this.http
      .post<any>(`${environment.apiUrl}googleLogin`, googleDetails)
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem("currentUser", JSON.stringify(user.details));
          localStorage.setItem("token", JSON.stringify(user.details.token));
          this.currentUserSubject.next(user.details);
          return user;
        })
      );
  }

  createPassword(payload, token) {
    return this.http.post<any>(
      `${environment.apiUrl}createPassword/` + token,
      payload
    );
  }

  changePassword(payload) {
    return this.http.patch<any>(`${environment.apiUrl}changePassword`, payload);
  }

  resetPassword(payload, token) {
    return this.http.patch<any>(
      `${environment.apiUrl}resetPassword/` + `${token}`,
      payload
    );
  }
  resetPasswordEikon(payload, token) {
    return this.http.patch<any>(
      `${environment.apiUrl}resetPasswordEikon/` + `${token}`,
      payload
    );
  }
  forgotPassword(payload) {
    return this.http.post<any>(`${environment.apiUrl}forgotPassword`, payload);
  }
  forgotPasswordEikon(payload) {
    return this.http.post<any>(`${environment.apiUrl}forgotPasswordEikon`, payload);
  }
  logout(SetCookie?) {
    // if(SetCookie){
    //   let cookie = this.cookies.get('url');
    //   // alert('logout inside cookie set block')
    //   if(!cookie){
    //     // this.cookies.set('url',location.pathname);
    // }
    // // alert('logout inside cookie set block after setting cookie'+location.pathname)
    // }
    
    let product =  localStorage.getItem("product");
    
    localStorage.clear();

    this.socialService.signOut(true);
    this.currentUserSubject.next(null);
    this.sharedService.profileDataObservable = null
    this.sharedService.profileDataObservableSOC = null
    this.stepService.currentStep$.next(this.stepService.steps$.value[0]);
    if(product){
      this.router.navigate(["/eikon-login"])
      // Refreshes the Screen which Restarts the Carousel and fixes the Auto Scroll 
      // .then(() => {
      //   window.location.reload();
      // });
    }
    else{
      this.router.navigate(["/"]);
    }
    
    // Don't Change to 'router.navigate' It'll have impact on Social Login
    // window.location.href = `${window.location.protocol}//${window.location.host}`;
    }

  logoutMember() {
  
    localStorage.clear();
    this.socialService.signOut(true);
    this.currentUserSubject.next(null);
    this.sharedService.profileDataObservable = null
    this.sharedService.profileDataObservableSOC = null
    this.stepService.currentStep$.next(this.stepService.steps$.value[0]);
    this.router.navigate(["/eikon-login"]);
    // Don't Change to 'router.navigate' It'll have impact on Social Login
    // window.location.href = `${window.location.protocol}//${window.location.host}`;
  }

  logoutMemberAfterFirstBatchEnterpriseUsers() {
    localStorage.clear();
    this.socialService.signOut(true);
    this.currentUserSubject.next(null);
    this.sharedService.profileDataObservable = null
    this.sharedService.profileDataObservableSOC = null
    this.stepService.currentStep$.next(this.stepService.steps$.value[0]);
    window.location.href = `https://www.wsmeikon.com/about-3`;
  }

  logoutMemberAfterFirstBatchNormalUsers() {
    localStorage.clear();
    this.socialService.signOut(true);
    this.currentUserSubject.next(null);
    this.sharedService.profileDataObservable = null
    this.sharedService.profileDataObservableSOC = null
    this.stepService.currentStep$.next(this.stepService.steps$.value[0]);
    window.location.href = ` https://www.wsmeikon.com/`;
  }

  accountLogout() {
    localStorage.clear();
    this.socialService.signOut(true);
    this.currentUserSubject.next(null);
    this.stepService.currentStep$.next(this.stepService.steps$.value[0]);
    this.router.navigate(["/accountlogin"]);
    // Don't Change to 'router.navigate' It'll have impact on Social Login
    // window.location.href = `${window.location.protocol}//${window.location.host}`;
  }

  async memberLogout(){
    // this.sharedService.showSpinner(true);
    // this.showSaveExit = true; 
    // let socCheck = await this.checkSoc();
    //   if(socCheck == true) {
    //     this.sharedService.showSpinner(false);
    //     this.showSaveExit = false;  
    //     this.router.navigate(["dashboard/rp/summary"]);
    //   }
    //   else {

        localStorage.clear();
        this.socialService.signOut(true);
        this.currentUserSubject.next(null);
        this.stepService.currentStep$.next(this.stepService.steps$.value[0]);
        // this.sharedService.showSpinner(false);
        this.showSaveExit = false; 
        
        this.router.navigate(["/login"]);
    //  }      
  }
  // Auth Redirect Rules
  redirectRules() {
    if (
      this.currentUserValue?.token &&
     this.currentUserValue?.role === "agent" &&
      this.currentUserValue?.onBoard
    ) {
      this.roleAuth();
      // const returnUrl =
      //   this.actRoute.snapshot.queryParamMap["returnUrl"] ||
      //   "dashboard/rp/profile";
        
      // this.router.navigate(["dashboard/rp/profile"]);

      
    } else if (
      this.currentUserValue?.token &&
      this.currentUserValue?.role === "seller"
    ) {
      if (localStorage.getItem("currentStep")) {
        if (
          localStorage.getItem("currentStep") == "5" ||
          localStorage.getItem("currentStep") == "10"
        ) {
          this.router.navigate(["dashboard/seller/onboarding-progress"]);
        } else {
          this.router.navigate(["dashboard/seller"]);
        }
      } else {
        this.router.navigate(["dashboard/isv"]);
      }
    } else if (
      this.currentUserValue?.token &&
      this.currentUserValue?.role === "buyer"
    ) {
      const returnUrl =
        this.actRoute.snapshot.queryParamMap["returnUrl"] ||
        "/dashboard/account/rewards";
      this.router.navigate(["/dashboard/account/rewards"]);
    } else if (
      this.currentUserValue?.token &&
      this.currentUserValue?.role === "agent"  &&
      this.currentUserValue?.onBoard == false
    ) {
      this.router.navigate(["dashboard/rp/termsandconditions"]);
    }
    else if (
      this.currentUserValue?.token &&
      this.currentUserValue?.role === "member"  &&
      this.currentUserValue?.onBoard == false
    ) {
      this.router.navigate(["dashboard/rp/extract-csv"]);
    }
  }
    // Auth Redirect Rules As member
    redirectRulesForMember() {
      if (
       this.currentUserValue?.token &&
       this.currentUserValue?.role === "member"  &&
       this.currentUserValue?.onBoard == false
     ) {
       this.router.navigate(["dashboard/rp/extract-csv"]);
     }
     else if(
      this.currentUserValue?.token &&
       this.currentUserValue?.role === "member"  &&
       this.currentUserValue?.onBoard 
     ){
      this.roleMemberAuth();
     }else if(
      this.currentUserValue?.token &&
       this.currentUserValue?.role === "agent"  &&
       this.currentUserValue?.onBoard 
     ){
      this.roleMemberAuth();
     }
   }




 async roleMemberAuth() { 
  this.cookieValue = this.cookies.get('eikonUrl');
 // console.log("this.currentUserValue =", this.currentUserValue)
     console.log("Cookie value",this.cookieValue);
    //  alert("rolememberauth")
     this.cookies.deleteAll();
    //  alert("cookie");
  if (
     this.currentUserValue.role === "member" &&
     this.currentUserValue.onBoard == false
   ) {
    //  this.router.navigate(["memberReg/123/1"]);
     this.router.navigate(["dashboard/rp/extract-csv"]);
   }
 
   //  else if (
   //   this.currentUserValue.role === "agent"  &&
   //   this.currentUserValue.onBoard === true
   // ) 
   // {
   //   let socCheck = await this.checkSoc();
   //   if(socCheck == true) {
   //     this.router.navigate(["dashboard/rp/summary"]);
   //   }
   //   else {
   //   this.router.navigate(["dashboard/member/soc"]);
   //  }     
   // } 
    else if (
     this.currentUserValue.role === "member" &&
     this.currentUserValue.onBoard === true
   ) {
    // alert("inside member block of roleMemberAuth");
    // let newRouterLink = 'dashboard/member/soc/1';
    // this.router.navigate(['/']).then(() => { this.router.navigate([newRouterLink ]); })
     // this.router.navigate(["dashboard/member/summary"]);
     
     
     if(this.cookieValue){
      this.router.navigate([this.cookieValue]);
     }
    else {
      // let socCheck = await this.scoreSoc();
      let socCheck = await this.checkMemberProgress();
      if(socCheck == true) {
        // alert("socCheck true");
        this.router.navigate(["dashboard/rp/network-visualization/card-view"]);
        
      }
      else {
        // alert("socCheck false");
       this.router.navigate(["dashboard/member/soc/1"]);     
     }     
    }
  

    

   }  else if (
    this.currentUserValue.role === "agent" &&
    this.currentUserValue.onBoard === false
  ) {
    // this.router.navigate(["memberReg/123/1"]);
    this.router.navigate(["dashboard/rp/extract-csv"]);
  }  else if (
    this.currentUserValue.role === "agent" &&
    this.currentUserValue.onBoard === true
  ) {
    // alert("inside agent block of roleMemberAuth");
    // this.router.navigate(["dashboard/member/summary"]);

    
    // this.router.navigate([this.cookieValue]);
    if(this.cookieValue){
      this.router.navigate([this.cookieValue]);
     }
    else {
      // let socCheck = await this.scoreSoc();
      let socCheck = await this.checkMemberProgress();
      if(socCheck == true) {
        // alert("socCheck True");
        this.router.navigate(["dashboard/rp/network-visualization/card-view"]);
        
      }
      else {
        // alert("socCheck false");
       this.router.navigate(["dashboard/member/soc/1"]);     
     }     
    }

    // this.router.navigate(["dashboard/member/soc/1"]);
  } 

  else if (this.currentUserValue.role === "CorporateAdmin" &&
  (this.currentUserValue.subscriptionType != "EikonEnterprise" || this.currentUserValue.subscriptionStatus != "Active")){
    this.router.navigate(["dashboard/corporate-admin"]);
  }
  else if (this.currentUserValue.role === "CorporateAdmin" &&
  this.currentUserValue.subscriptionType === "EikonEnterprise" && this.currentUserValue.subscriptionStatus === "Active") {
    this.router.navigate(["dashboard/member/network-visualization/manage-corporate-users"]);

    
  }
  
  else if (this.currentUserValue.role === "admin"){
    this.router.navigate(["dashboard/member/network-visualization/manage-eikon-users"]);
  }
 }

  verifyEmail(token) {
    return this.http.patch<any>(
      `${environment.apiUrl}verifyEmail/` + token,
      {}
    );
  }

  async roleAuth() {
    // alert('roleAuth'+location.hostname)
    this.cookieValue = this.cookies.get('eikonUrl');
    console.log("VALUE+++++++++++", this.cookieValue)
    // this.cookies.deleteAll('/', location.hostname);
    // this.cookies.deleteAll('url');
    this.cookies.deleteAll();
    // this.cookies.delete('eikonUrl');
    // alert('roleAuth after deleting cookie'+this.cookies.get('url'))

    if (
      this.currentUserValue.role === "seller" &&
      this.currentUserValue.onBoard == true
    ) {
      // this.cookieValue = this.cookies.get('url');
      // this.router.navigate([this.cookieValue]);


      this.router.navigate(["dashboard/isv"]);

    } else if (
      this.currentUserValue.role === "seller" &&
      this.currentUserValue.onBoard == false
    ) {

      // this.cookieValue = this.cookies.get('url');
      // this.router.navigate([this.cookieValue]);

      this.router.navigate(["dashboard/seller"]);

    } else if (
      this.currentUserValue.role === "agent"  &&
      this.currentUserValue.onBoard == false
    ) {
      // this.cookieValue = this.cookies.get('url');
      // this.router.navigate([this.cookieValue]);

      this.router.navigate(["dashboard/rp/termsandconditions"]);
    }
    else if (
      this.currentUserValue.role === "member" &&
      this.currentUserValue.onBoard == false
    ) 
    {
      // this.cookieValue = this.cookies.get('url');
      // this.router.navigate([this.cookieValue]);

      this.router.navigate(["dashboard/rp/extract-csv"]);

    } else if (
      this.currentUserValue.role === "agent"  &&
      this.currentUserValue.onBoard === true
    ) {
      if(this.cookieValue){
        this.router.navigate([this.cookieValue]);
       }else{

        let socCheck = await this.checkSoc();
      if(socCheck == true) {
        this.router.navigate(["dashboard/rp/summary"]);
      }
      else {
      this.router.navigate(["dashboard/member/soc/1"]);
     }
       }

           
    } 
    //  else if (
    //   this.currentUserValue.role === "member" &&
    //   this.currentUserValue.onBoard === true
    // )
     //{
      // this.router.navigate(["dashboard/member/summary"]);
      //this.router.navigate(["dashboard/member/soc/1"]);
    //} 
    else if (this.currentUserValue.role === "buyer") {
      // this.router.navigate(["/dashboard/account/rewards"]);
      // this.cookieValue = this.cookies.get('url');
      // this.router.navigate([this.cookieValue]);

      this.router.navigate(["/dashboard/account/ac-dashboard"]);
    }
    else if (this.currentUserValue.role === "corporateAdmin" &&
    (this.currentUserValue.subscriptionType != "EikonEnterprise" || this.currentUserValue.subscriptionStatus != "Active")) {
      this.router.navigate(["/dashboard/corporate-admin"]);
    }
    else if (this.currentUserValue.role === "CorporateAdmin" &&
    this.currentUserValue.subscriptionType === "EikonEnterprise" && this.currentUserValue.subscriptionStatus === "Active") {
      this.router.navigate(["dashboard/member/network-visualization/manage-corporate-users"]);    
    }
    else if (this.currentUserValue.role === "admin"){
      this.router.navigate(["dashboard/member/network-visualization/manage-eikon-users"]);
    }
  }

 public checkSoc(){
   let promise1 = new Promise((resolve,reject)=>{
    let userId = this.currentUserValue.userId;
   this.sharedService.getProfileInfo(userId).subscribe((res) => {
     this.profiles = res.data.connectionMap;
      const countOfCategory = this.profiles.filter(element=>element.category=="C&SVP LEVEL");
      const countOfSOC = this.profiles.filter(element=>element.category=="C&SVP LEVEL" && element.strengthOfConnection!==null);
      if(countOfCategory.length == countOfSOC.length) {
         resolve(true);
      }
      else {
        resolve(false);
      }  
    }) 
   })
   return promise1;
  }

  public scoreSoc(){
    let promise1 = new Promise((resolve,reject)=>{
      let userId = this.currentUserValue.userId;
     this.sharedService.getProfileInfo(userId).subscribe((res) => {
       this.profiles = res.data.connectionMap;
        const countOfSOC = this.profiles.filter(element=> element.strengthOfConnection==null);
        if(countOfSOC.length==0) {
           resolve(true);
        }
        else {
          resolve(false);
        }  
      }) 
     })
     return promise1;
  }

  public checkMemberProgress(){
    let promise1 = new Promise((resolve,reject)=>{
      let userId = this.currentUserValue.userId;
     this.sharedService.checkMemberProgress(userId).subscribe((memberProgress) => {
        let allConnectionsScored = memberProgress?.allConnectionsScored;
        if(allConnectionsScored == true) {
           resolve(true);
        }
        else {
          resolve(false);
        }  
      }) 
     })
     return promise1;
  }

  public createPasswordRedirect() {
    this.router.navigate(["/change-password/" + this.currentUserValue.userId]);
  }
  // addTargetToNetwork(payload) {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
  //     }),
  //   };
  //   return this.http.post<any>(
  //     `${environment.apiUrl}addTargetToNetwork`,
  //     payload,
  //     httpOptions
  //   );
  // }
  getNature() {
    return this.http.get<any>(`${environment.apiUrl}nature`);
  }
  getStrength() {
    return this.http.get<any>(`${environment.apiUrl}strength`);
  }
  accountDropdown() {
    return this.http.get<any>(`${environment.apiUrl}accountDropdown`);
  }


  expertiseDropdown() {
    return this.http.get<any>(`${environment.apiUrl}expertiseDropdown`);
  }

  getAllAccountDropdown() {
    return this.http.get<any>(`${environment.apiUrl}accountDropDown/`).pipe(
      map((el) => {
        return el;
      })
    );
  }

  getConnections(count, page, skip, days, account, searchKey, strength) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      }),
    };
    if (
      account != undefined &&
      account != "None" &&
      account != "All" &&
      (strength == undefined || strength == "None")
    ) {
      return this.http.get<any>(
        `${environment.apiUrl}getWsmNetworkForRp` +
        `?count=${count}&page=${page}&skip=${skip}&days=${days}&account=${account}`,
        httpOptions
      );
    }
    if (searchKey != undefined) {
      return this.http.get<any>(
        `${environment.apiUrl}getWsmNetworkForRp` +
        `?count=${count}&page=${page}&skip=${skip}&days=${days}&term=${searchKey}`,
        httpOptions
      );
    }
    if (
      strength != undefined &&
      (account == undefined || account == "None" || account == "All")
    ) {
      return this.http.get<any>(
        `${environment.apiUrl}getWsmNetworkForRp` +
        `?count=${count}&page=${page}&skip=${skip}&days=${days}&strength=${strength}`,
        httpOptions
      );
    }
    return this.http.get<any>(
      `${environment.apiUrl}getWsmNetworkForRp` +
      `?count=${count}&page=${page}&skip=${skip}&days=${days}`,
      httpOptions
    );
  }

  editConnection(token, data) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      }),
    };
    return this.http.patch<any>(
      `${environment.apiUrl}connections/` + token,
      data,
      httpOptions
    );
  }
  getAllISV() {
    return this.http.get<any>(`${environment.apiUrl}allIsvs`);
  }
  sendMessage(message: string) {
    this._teacherMessageSource.next(message);
  }
}
