import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { IsvService } from './isv.service';
import { SharedService } from './shared.service';

const STEPS = [
    { stepIndex: 1, isComplete: false },
    { stepIndex: 2, isComplete: false },
    { stepIndex: 3, isComplete: false },
    { stepIndex: 4, isComplete: false },
    { stepIndex: 5, isComplete: false },
    { stepIndex: 6, isComplete: false },
    { stepIndex: 7, isComplete: false },
    { stepIndex: 8, isComplete: false },
    { stepIndex: 9, isComplete: false },
    { stepIndex: 10, isComplete: false },
    { stepIndex: 11, isComplete: false }
];

export interface StepModel {
    stepIndex: number;
    isComplete: boolean;
}

@Injectable({
    providedIn: "root",
})

export class StepsService {
    steps$: BehaviorSubject<StepModel[]> = new BehaviorSubject<StepModel[]>(STEPS);
    currentStep$: BehaviorSubject<StepModel> = new BehaviorSubject<StepModel>(null);
    lastVisitedStep$: BehaviorSubject<StepModel> = new BehaviorSubject<StepModel>(null);

    constructor(public isvService: IsvService, public sharedService: SharedService, private router: Router) {
        let currentIndex = localStorage.getItem('currentStep');
        if (currentIndex) {
            const ind = JSON.parse(currentIndex);
            this.currentStep$.next(this.steps$.value[ind]);
            this.lastVisitedStep$.next(this.steps$.value[ind]);
        } else {
            // localStorage.setItem('currentStep', JSON.stringify(0));
            this.currentStep$.next(this.steps$.value[0]);
            this.lastVisitedStep$.next(this.steps$.value[0]);
        }
    }

    setCurrentStep(step): void {
        this.currentStep$.next(this.steps$.value[step]);
    }

    getCurrentStep(): Observable<StepModel> {
        return this.currentStep$.asObservable();
    }

    getSteps(): Observable<StepModel[]> {
        return this.steps$.asObservable();
    }

    moveToNextStep(): void {
        const index = this.currentStep$.value.stepIndex;
        localStorage.setItem('currentStep', JSON.stringify(index));

        if (index < this.steps$.value.length) {
            this.currentStep$.next(this.steps$.value[index]);
        }

        if(index >= this.lastVisitedStep$.value.stepIndex) {
            this.lastVisitedStep$.next(this.steps$.value[index]);
        }
    }

    moveToPrevStep(): void {
        let prevIndex = this.currentStep$.value.stepIndex - 2;

        localStorage.setItem('currentStep', JSON.stringify(prevIndex));
        this.currentStep$.next(this.steps$.value[prevIndex]);
    }

    isLastStep(): boolean {
        return this.currentStep$.value.stepIndex === this.steps$.value.length;
    }

    onNextStep() {
        if (!this.isLastStep()) {
            this.moveToNextStep();
        } else {
            this.onSubmit();
        }
    }

    onSubmit() { }

    getProductDetail(step) {
        let prodID = localStorage.getItem('productId');
        let productDetails = JSON.parse(localStorage.getItem('productDetails'));
        return new Observable(observer => {
            if (!productDetails) {
                this.isvService.getSellerProductRegData(prodID, step).subscribe(
                    data => {
                        localStorage.setItem('productDetails', JSON.stringify(data.details.product))
                        observer.next(data.details.product);
                    },
                    err => {
                        // console.log(err);
                        observer.next(true);
                        this.sharedService.showToastMessage('ERROR', err.error.message, 5000);
                    }
                )
            }
            else {
                observer.next(productDetails);
                observer.complete();
           }
        })
    }

    isOnPreviousStep(): boolean {
        return this.lastVisitedStep$.value.stepIndex > this.currentStep$.value.stepIndex;
    }

    resetLastVisitedStep(): void {
        this.lastVisitedStep$.next(this.steps$.value[0]);
    }
}
