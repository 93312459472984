import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewEncapsulation } from '@angular/core';

export interface DialogData {
  message: string;
}
@Component({
  selector: 'app-dialog-success-message',
  templateUrl: './dialog-success-message.component.html',
  styleUrls: ['./dialog-success-message.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogSuccessMessageComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<DialogSuccessMessageComponent>
  ) { }

  ngOnInit(): void {
  }

  closeBtnClick(): void {
    this.dialogRef.close();
  }

}
