import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

enum localStroageKeys {
  IntroRequest = 'intro-request',
  TargetList = 'targetList',
  Seller = 'seller-matching',
  Clevel = 'clevelList',
  SelectedIntroRequest = 'selectedIntroRequest',
}

type activeTabName = 'intro-requests' | 'match-with-network' | 'c-level-connects';

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {
  private _targetTriger: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public targetTriger = this._targetTriger.asObservable();

  private _targetList: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public targetList = this._targetList.asObservable();
  private checkedTargetIds = {};

  private _clevelList: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public clevelList = this._clevelList.asObservable();
  private checkedClevelList = {};

  private _introRequest: BehaviorSubject<any> = new BehaviorSubject({});
  public introRequest = this._introRequest.asObservable();

  private _activeTab: Subject<activeTabName> = new Subject();
  public activeTab = this._activeTab.asObservable();

  constructor() {
    this.getTargetList();
    this.getClevelTargetList();
    this.subscribeToTargetList();
    this.subscribeToClevelList();
  }

  private subscribeToTargetList(): void {
    this.targetList.subscribe(list => {
      this.checkedTargetIds = {};

      list.forEach(el => {
        this.checkedTargetIds[el._id] = true;
      })
    });
  }

  private subscribeToClevelList(): void {
    this.clevelList.subscribe(list => {
      this.checkedClevelList = {};

      list.forEach(el => {
        this.checkedClevelList[el._id] = true;
      });
    })
  }
  
  //#region Intro Request
  public getIntroRequest() {
    const requestStr = localStorage.getItem(localStroageKeys.IntroRequest);
    const parsed = JSON.parse(requestStr);
    
    return parsed;
  }

  public saveIntroRequest(request) {
    const stringified = JSON.stringify(request);
    this._introRequest.next(request);

    if(stringified) {
      localStorage.setItem(localStroageKeys.IntroRequest, stringified);
    }
  }

  public clearIntroRequest() {
    localStorage.removeItem(localStroageKeys.IntroRequest);
  }

  public getSelectedIntroRequest(): string {
    return localStorage.getItem(localStroageKeys.SelectedIntroRequest);
  }

  public setSelectedIntroRequest(requestId: string) {
    localStorage.setItem(localStroageKeys.SelectedIntroRequest, requestId);
  }

  public clearSelectedIntroRequest() {
    localStorage.removeItem(localStroageKeys.SelectedIntroRequest);
  }
  //#endregion

  //#region Seller for intro requests
  public getSellerFromIntroRequest() {
    const sellerStr = localStorage.getItem(localStroageKeys.Seller);
    
    return JSON.parse(sellerStr);
  }

  public saveSeller(seller) {
    const stringified = JSON.stringify(seller);

    if(stringified) {
      localStorage.setItem(localStroageKeys.Seller, stringified);
    }
  }

  public clearSeller() {
    localStorage.removeItem(localStroageKeys.Seller);
  }
  //#endregion

  //#region Target List
  public getTargetList() {
    const targetListStr = localStorage.getItem(localStroageKeys.TargetList);
    
    if(targetListStr === null) {
      return [];
    }

    const targetListObj = JSON.parse(targetListStr);
    this._targetList.next(targetListObj);

    return targetListObj;
  }
  public trigerTarget(): void {
    this._targetTriger.next(true);
  }

  public saveTargetList(targetList) {
    const targetListStr = JSON.stringify(targetList)
    // console.log(targetListStr);
    localStorage.setItem(localStroageKeys.TargetList, targetListStr);
  }

  public updateTarget(updatedTarget) {
    const targetList = this.getTargetList();
    
    const index = targetList.findIndex(target => {
      return target._id == updatedTarget._id;
    });

    targetList[index] = updatedTarget;
    this._targetList.next(targetList);
    this.saveTargetList(targetList);
  }

  public addToTargetList(item) {
    const targetList = this.getTargetList();
    targetList.push(item);

    this._targetList.next(targetList);

    this.saveTargetList(targetList);
  }

  public removeFromTargetList(targetId: string) {
    const targetList = this.getTargetList();

    const index = targetList.findIndex(target => {
      return target._id == targetId;
    });

    targetList.splice(index, 1);
    this.saveTargetList(targetList);
    this._targetList.next(targetList);
  }

  public targetItemChecked(targetId) : boolean {
    return this.checkedTargetIds[targetId];
  }

  public clearTargetList() {
    this._targetList.next([]);
    localStorage.removeItem(localStroageKeys.TargetList);
  }
  //#endregion

  //#region Clevel List
  public getClevelTargetList(): any[] {
    const targetListStr = localStorage.getItem(localStroageKeys.Clevel);
    
    if(targetListStr === null) {
      return [];
    }

    const targetListObj = JSON.parse(targetListStr);
    this._clevelList.next(targetListObj);

    return targetListObj;
  }

  public saveClevelTargetList(targetList) {
    const targetListStr = JSON.stringify(targetList)
    localStorage.setItem(localStroageKeys.Clevel, targetListStr);
  }

  public addClevelToTargetList(item) {
    const targetList = this.getClevelTargetList();
    targetList.push(item);

    this._clevelList.next(targetList);

    this.saveClevelTargetList(targetList);
  }

  public removeClevelFromTargetList(targetId: string) {
    const targetList = this.getClevelTargetList();

    const index = targetList.findIndex(target => {
      return target._id == targetId;
    });

    targetList.splice(index, 1);
    this.saveClevelTargetList(targetList);
    this._clevelList.next(targetList);
  }

  public targeCleveltItemChecked(targetId) : boolean {
    return this.checkedClevelList[targetId];
  }

  public clearClevelTargetList() {
    this._clevelList.next([]);
    localStorage.removeItem(localStroageKeys.Clevel);
  }

  public getClevelFromList(id: string) {
    const targetList = this.getClevelTargetList();

    const target = targetList.find(target => {
      return target._id == id;
    });

    return target;
  }
  //#endregion

  public setActiveTab(tabName: activeTabName ) {
    this._activeTab.next(tabName);
  }
}
