import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class DateInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const utcDate = new Date().toUTCString();
        
        request = request.clone({
            setHeaders: {
              'utc-date': utcDate 
            },
          });

        return next.handle(request);
    }
}