import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-base-unsubscribee',
  templateUrl: './base-unsubscribee.component.html',
  styleUrls: ['./base-unsubscribee.component.scss']
})
export class BaseUnsubscribeeComponent implements OnDestroy {
  protected unsubscribe: Subject<boolean> = new Subject();

  constructor() {
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
